import React, { useMemo } from 'react';
import { graphql, useStaticQuery, PageProps } from 'gatsby';
import { LayoutProvider, Wrapper } from '../components/layout';
import { ProductFilters, ProductsResults } from '../components/sections';
import { StackOnBreadcrumbs } from '../atoms';
import { stackStyled } from '../theme';
import { useQueryObjState } from '../hooks/useQueryState';
import { useHandleLinks, useViewPortSize } from '../hooks';
import { ImageDataLike } from 'gatsby-plugin-image';
import { IDataImageFile } from '../@types';

const query = graphql`
  query {
    allStrapiProducts {
      edges {
        node {
          productName
          cost
          popular
          electronicDoor
          doorOrganizer
          slug
          fireArmCapacity
          features {
            waterProof
            fireProof
          }
          stack_on_category {
            category
            id
          }
          reviews {
            rating
          }
          productImageHover {
            ext
            url
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: NONE
                )
              }
            }
          }
          productImage {
            ext
            url
            localFile {
              childImageSharp {
                gatsbyImageData(
                  height: 10000
                  width: 1000
                  placeholder: NONE
                  transformOptions: { fit: COVER }
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
      }
    }
    allStrapiStackOnCategories {
      edges {
        node {
          category
          slug
        }
      }
    }
  }
`;

export interface FilterOption {
  category: any;
  id: number;
}

interface IMeasures {
  depth: number;
  height: number;
  width: number;
}

interface IReviews {
  rating: number;
}

export interface IProductsDetails {
  productName: string;
  cost: number;
  electronicDoor: boolean;
  doorOrganizer: boolean;
  productImageHover: IDataImageFile;
  productImage: IDataImageFile;
  slug: string;
  popular: boolean;
  features?: {
    waterProof: boolean;
    fireProof: boolean;
  };
  stack_on_category: {
    category: string;
  };
  reviews: Array<IReviews>;
  fireArmCapacity: any;
  dimensions: {
    size: string;
    weight: number;
    exteriorMeasures: IMeasures;
  };
}

export interface IData {
  allStrapiProducts: {
    edges: Array<{ node: IProductsDetails }>;
  };
  allStrapiStackOnCategories: {
    edges: Array<{
      node: {
        category: string;
        slug: string;
      };
    }>;
  };
}

export interface ISafesFilters {
  bottomPrice: number;
  topPrice: number;
  category: Array<string>;
  features: Array<string>;
  sortBy: string;
}

export const defaultSafeFilters: ISafesFilters = {
  bottomPrice: 25,
  topPrice: 3000,
  category: [],
  features: [],
  sortBy: '',
};

interface IisMobile {
  isMobile: boolean;
}

const SafesWrapper = stackStyled(Wrapper, (props: IisMobile) => ({
  display: 'flex',
  maxWidth: '1440px',
  padding: props.isMobile ? '50px 0px' : '50px 0 0 60px',
  overflow: 'hidden',
  // width: props.isMobile? ' ' : '100%',
  margin: props.isMobile ? '0' : '0 0 100px 0',
}));

const BreadCrumbsContainer = stackStyled('div', (props: IisMobile) => ({
  position: 'absolute',
  top: props.isMobile ? '10px' : '40px',
  left: props.isMobile ? '36px' : '60px',
  width: 'fit-content',
}));

const FlexMargin = stackStyled('div', () => ({
  marginTop: '40px',
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
})) 

const getMapedEdges = (responseArray) => {
  return responseArray.edges.map((el) => el.node);
};

const SafesPage: React.FC<PageProps> = (props: PageProps) => {
  const data = useStaticQuery<IData>(query);
  const [filters, setFilters, resetValues] = useQueryObjState(
    props.location.search,
    defaultSafeFilters
  );

  const [isMobile, isTablet] = useViewPortSize();

  const [filteredProducts, recommendations] = useMemo(() => {
    const allProducts = data.allStrapiProducts.edges.map((el) => el.node);

    let filteredProducts = allProducts.filter((s) => {
      if (s.cost < filters.bottomPrice || s.cost > filters.topPrice) {
        return false;
      }
      // Waterproof filters
      if (filters.features.includes('waterproof') && s.features?.waterProof == false) {
        return false;
      }
      // Fireproof locks filters
      if (filters.features.includes('fireproof') && s.features?.fireProof == false) {
        return false;
      }
      // Door Organizer locks filters
      if (filters.features.includes('doorOrganizer') && s.doorOrganizer === false) {
        return false;
      }
      // Electronic Door locks filters
      if (filters.features.includes('electronicDoor') && s.electronicDoor === false) {
        return false;
      }
      //type filters
      if (filters.category.length > 0 && !filters.category.includes(s.stack_on_category.category)) {
        return false;
      }
      return true;
    });

    const newFiltered = filteredProducts.map((li) => {
        li.reviewsValue =
          li.reviews.reduce((acc, current) => acc + current.rating, 0) / li.reviews.length;
        return li;
      });

    switch (filters.sortBy) {
      case 'popular':
        filteredProducts = filteredProducts.sort((a, b) =>
          a.popular > b.popular ? -1 : b.popular > a.popular ? 1 : 0
        );
        break;
      case 'lowFirst':
        filteredProducts = newFiltered.sort((a, b) => a.cost - b.cost);
        break;
      case 'highFirst':
        filteredProducts = newFiltered.sort((a, b) => b.cost - a.cost);
        break;
      case 'bestRated':
        filteredProducts = newFiltered.sort((a, b) => b.reviewsValue - a.reviewsValue);
        break;
      default:
        filteredProducts;
    }

    return [filteredProducts, allProducts.slice(0, isTablet ? 2 : 3)];
  }, [filters, isTablet]);

  const [categoryOptions] = useMemo(() => [getMapedEdges(data.allStrapiStackOnCategories)], []);

  const breadCrumbsPages = useMemo(() => {
    const bPages = [
      { title: 'Home', href: '/' },
      { title: 'Products', href: '/products' },
    ];
    if (filters?.category?.length === 1) {
      let href = useHandleLinks(filters.category);
      bPages.push({
        title: filters.category[0],
        href: `/categories/${href}`,
      });
    } else {
      bPages.push({
        title: "All products",
        href: `/products`,
      });
    }
    return bPages;
  }, [filters.category.length]);

  let addTitle = props.location.search
    ? props.location.search.split('=')[1].split('+').join(' ')
    : '';

  return (
    <LayoutProvider seo={false} locationSearch={props.location.search} titleExt={addTitle}>
      <SafesWrapper isMobile={isMobile || isTablet} noScroll id='safesWrapper'>
        <BreadCrumbsContainer isMobile={isMobile}>
          <StackOnBreadcrumbs pages={breadCrumbsPages} />
        </BreadCrumbsContainer>
        <FlexMargin>
          <ProductFilters
            {...filters}
            categoryOptions={categoryOptions}
            setFilters={setFilters}
            resetFilters={resetValues}
          />
          <ProductsResults products={filteredProducts} recommendations={recommendations} />
        </FlexMargin>
      </SafesWrapper>
    </LayoutProvider>
  );
};

export default SafesPage;
